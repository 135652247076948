html, body, #root {
    height: 100%;
    margin: 0;
}

.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content {
    flex: 1;
}
